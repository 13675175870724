<template>
  <v-container>
    <v-row justify="center">
      <v-col class="text-center">
        <img
          v-if="$vuetify.theme.dark"
          src="../assets/ssencial-logo-white.svg"
          height="100"
          alt="ssencial"
        />
        <img
          v-else
          src="../assets/ssencial-logo.svg"
          height="100"
          alt="ssencial"
        />
        <div class="mt-3">
          <h3 class="text-h5">404</h3>
          <p>Pagina no encontrada</p>
          <p class="caption">
            Al parecer la página que estás buscando no está disponible o a
            cambiado, corrobora la dirección e inta de nuevo.
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
